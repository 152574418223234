import { orderBy } from 'lodash'
import { useTranslation } from 'react-i18next'

import { useReportsMenu } from 'features/reportsModularSidebar/hooks/useReportsMenu'

import { QueryStatus } from 'store/types'
import { tCode } from 'utils/tCode'

import { IReportsSidebarMenuGroup } from '../types/reportSidebarMenuGroup'

export const useMenuGroupAreas = (): {
  sidebarItems: IReportsSidebarMenuGroup[]
  status: QueryStatus
} => {
  const { t } = useTranslation()
  const tArea = tCode(t)
  const { menu, status } = useReportsMenu()

  if (status === QueryStatus.ERROR || status === QueryStatus.LOADING) {
    return {
      sidebarItems: [],
      status,
    }
  }

  // Same code in useMenuEmployeeGroups
  // not a priority to touch this now but should be consolidated
  const sidebarItems: IReportsSidebarMenuGroup[] = [...menu]?.map(
    ({ code, items, url, baseUrl, sorted, enabled }: any) => ({
      code,
      enabled: enabled === undefined ? true : enabled,
      text: tArea(code, 'title', code),
      url: url || `/reports/${code}`,
      baseUrl,
      items: orderBy(
        items.map(({ code: subCode, title, text }: any) => ({
          code: subCode,
          text: text || tArea(subCode, 'title', title),
          url: `/reports/${code}/${encodeURIComponent(subCode)}`,
        })),
        sorted ? ['text'] : []
      ),
    })
  )

  return { sidebarItems, status: QueryStatus.SUCCESS }
}
