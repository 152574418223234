import React from 'react'

import { useMenuGroupDiversity } from './hooks/useMenuGroupDiversity'
import { MenuGroup } from './MenuGroup'

export const MenuGroupDiversity = () => {
  const { sidebarItems: sidebarDiversityItem } = useMenuGroupDiversity()

  if (!sidebarDiversityItem) {
    return null
  }

  return (
    <MenuGroup key={sidebarDiversityItem?.text} {...sidebarDiversityItem} />
  )
}
