import React from 'react'

import { useMenuGroupEquality } from './hooks/useMenuGroupEquality'
import { MenuGroup } from './MenuGroup'

export const MenuGroupEquality = () => {
  const { sidebarItems: sidebarEqualityItem } = useMenuGroupEquality()

  if (!sidebarEqualityItem) {
    return null
  }

  return <MenuGroup key={sidebarEqualityItem?.text} {...sidebarEqualityItem} />
}
