import { FunctionComponent } from 'react'

import { Box } from '@theme-ui/components'

import Sidebar from 'layout/Sidebar'
import { useGetEntitlementsQuery } from 'store/company/companyApiWithQuery'
import { EntitlementsEnum } from 'store/company/types'

import { MenuGroupAreas } from './MenuGroupAreas'
import { MenuGroupDiversity } from './MenuGroupDiversity'
import { MenuGroupEmployeeGroups } from './MenuGroupEmployeeGroups'
import { MenuGroupEquality } from './MenuGroupEquality'
import { MenuGroupExperience } from './MenuGroupExperience'

export const ReportsModularSidebar: FunctionComponent = () => {
  const { data: companyEntitlements } = useGetEntitlementsQuery()

  // Sidebar items follow an specific business order
  return (
    <Sidebar sx={{ py: 6 }}>
      <Box sx={{ position: 'sticky', bottom: 0 }}>
        {companyEntitlements?.entitlements.includes(
          EntitlementsEnum.REPORTS ||
            companyEntitlements?.entitlements.includes(
              EntitlementsEnum.REPORTS_INSIGHTS
            )
        ) && <MenuGroupAreas />}

        {(companyEntitlements?.entitlements.includes(
          EntitlementsEnum.PRIVEQ_CERTIFICATION_REPORTS
        ) ||
          companyEntitlements?.entitlements.includes(
            EntitlementsEnum.DIVERSITYVC_CERTIFICATION_REPORTS
          )) && <MenuGroupEquality />}

        {companyEntitlements?.entitlements.includes(
          EntitlementsEnum.DEM_REPORT
        ) && <MenuGroupDiversity />}

        {companyEntitlements?.entitlements.includes(EntitlementsEnum.IS) && (
          <MenuGroupExperience />
        )}

        {companyEntitlements?.entitlements.includes(
          EntitlementsEnum.REPORTS ||
            companyEntitlements?.entitlements.includes(
              EntitlementsEnum.REPORTS_INSIGHTS
            )
        ) && <MenuGroupEmployeeGroups />}
      </Box>
    </Sidebar>
  )
}
