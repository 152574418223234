import { FunctionComponent } from 'react'

import { Flex, Heading } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'

import { Navigate as Redirect } from 'react-router-dom'

import { Spinner, Text, baseColors } from '@fairhq/common'

import { DATE_FORMAT_SESSION_URL } from 'const'
import { SessionDropdown } from 'features/changelog/SessionDropdown'
import { ReportsCore } from 'features/reportsCore/ReportsCore'
import { ReportsModularSidebar } from 'features/reportsModularSidebar/ReportsModularSidebar'
import { Layout } from 'layout/Layout'
import { apiHeadersActions } from 'store/apiHeaders/apiHeadersSlice'
import { getAudit } from 'store/audit/auditSlice'
import {
  useGetEntitlementsQuery,
  useGetSessionsQuery,
} from 'store/company/companyApiWithQuery'
import { companyActions } from 'store/company/companySlice'
import { EntitlementsEnum } from 'store/company/types'
import { useCurrentlyViewingOrLatestSession } from 'store/company/useCurrentlyViewingOrLatestSession'
import { useAppDispatch } from 'store/hooks'
import { clear as clearReports } from 'store/reports/reportsSlice'
import { toDate } from 'utils/dates'

export const Reports: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation()

  const { data: sessions } = useGetSessionsQuery()
  const { session: latestSession } = useCurrentlyViewingOrLatestSession()

  const {
    data: companyEntitlements,
    isSuccess,
    isLoading,
    isError,
  } = useGetEntitlementsQuery()

  if (
    isSuccess &&
    (companyEntitlements?.entitlements.includes(
      EntitlementsEnum.DIVERSITYVC_CERTIFICATION_REPORTS
    ) ||
      companyEntitlements?.entitlements.includes(
        EntitlementsEnum.PRIVEQ_CERTIFICATION_REPORTS
      ))
  ) {
    return <Redirect to="equality" />
  }

  if (
    isSuccess &&
    companyEntitlements?.entitlements.includes(EntitlementsEnum.DEM_REPORT)
  ) {
    return <Redirect to="diversity" />
  }

  return (
    <Layout sidebar={<ReportsModularSidebar />} dark>
      <Flex
        sx={{ justifyContent: 'space-between', alignItems: 'center', mb: 6 }}
      >
        <Heading mr={4}>{t('nav.reports')}</Heading>
        <SessionDropdown
          sessions={sessions || []}
          hidden={(sessions || []).length <= 1}
          value={
            latestSession?.start_date &&
            toDate(i18n.language, latestSession?.start_date).format(
              DATE_FORMAT_SESSION_URL
            )
          }
          onChange={e => {
            const newSession = sessions?.find(
              ({ start_date }) =>
                toDate(i18n.language, start_date).format(
                  DATE_FORMAT_SESSION_URL
                ) === e?.target?.value
            )

            if (newSession) {
              dispatch(apiHeadersActions.setSessionId(newSession.id))
              dispatch(apiHeadersActions.setApiVersion(newSession.api_version))
              dispatch(clearReports())
              dispatch(companyActions.setSessionCurrentlyViewing(newSession.id))
              dispatch(getAudit())
            }
          }}
        />
      </Flex>

      {isLoading && <Spinner center />}
      {isError && (
        <Text
          element="p"
          type="body"
          size="md"
          fontWeight={600}
          color={baseColors.red}
        >
          {t('error.TryAgain')}
        </Text>
      )}

      {isSuccess &&
        (companyEntitlements?.entitlements.includes(EntitlementsEnum.REPORTS) ||
          companyEntitlements?.entitlements.includes(
            EntitlementsEnum.REPORTS_INSIGHTS
          )) && <ReportsCore />}
    </Layout>
  )
}
