import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'

import { AreaType } from 'store/area/types'
import { useGetAuditAreasQuery } from 'store/audit/auditApiWithQuery'
import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'
import { QueryStatus } from 'store/types'

import { IReportsSidebarMenuGroup } from '../types/reportSidebarMenuGroup'

export const useMenuGroupEquality = (): {
  sidebarItems: IReportsSidebarMenuGroup | undefined
  status: QueryStatus
} => {
  const { sessionId } = useAppSelector(
    (state: State) => ({
      sessionId: state.apiHeadersReducer.sessionId,
    }),
    shallowEqual
  )

  const { t } = useTranslation()

  const {
    data: localAreas,
    isSuccess: isAreasSucess,
    isLoading: isAreaLoading,
    isError: isAreaError,
  } = useGetAuditAreasQuery({
    sessionId,
    query: { type: AreaType.LOCAL },
  })

  if (isAreaLoading || isAreaError) {
    return {
      sidebarItems: undefined,
      status: isAreaLoading ? QueryStatus.LOADING : QueryStatus.ERROR,
    }
  }

  if (isAreasSucess && localAreas && localAreas.length > 0) {
    const EQUALITY_CODE = 'equality'

    const equalityLocalAreas = localAreas.filter(
      ({ parent, max }: any) => max && parent?.parentAreaCode === EQUALITY_CODE
    )
    const sidebarItems: IReportsSidebarMenuGroup = {
      code: EQUALITY_CODE,
      text: t(`${EQUALITY_CODE}.title`),
      enabled: true,
      url: `/reports/${EQUALITY_CODE}`,
      items: equalityLocalAreas.map(({ code, title }: any) => ({
        code,
        text: title,
        url: `/reports/${EQUALITY_CODE}/${encodeURIComponent(code)}`,
      })),
    }

    return {
      sidebarItems,
      status: QueryStatus.SUCCESS,
    }
  }

  return { sidebarItems: undefined, status: QueryStatus.SUCCESS }
}
