import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'

import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'

import { Text } from '@fairhq/common'
import { StateHandler } from 'components/StateHandler'
import { Tab } from 'components/Tabs'

import {
  COLOR_RULES_POC,
  COLOR_RULES_WOMEN,
} from 'components/visualization/constants'
import { getLegend } from 'components/visualization/utils/getLegend'
import { useMenuGroupAreas } from 'features/reportsModularSidebar/hooks/useMenuGroupAreas'
import { useAppSelector } from 'store/hooks'

import { useGetReportByAreaQuery } from 'store/reports/reportsApiWithQuery'
import { ReportGroup } from 'store/reports/types'
import { State } from 'store/state'

import { getTabs } from '../utils/getTabs'
import { getDataByTab } from '../utils/representation/getDataByTab'

import { Representation } from './Representation'

const CenteredText = styled(Text)`
  display: flex;
  justify-content: center;
`

interface DiversityRepresentationProps {
  code: string
  group: ReportGroup
}

export const DiversityRepresentation: FunctionComponent<
  DiversityRepresentationProps
> = ({ code, group }) => {
  const { t } = useTranslation()

  const { apiVersion, sessionId } = useAppSelector(
    (state: State) => ({
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer.sessionId,
    }),
    shallowEqual
  )

  const {
    data: jobLevelReportsData,
    isLoading: isJobLevelLoading,
    isError: isJobLevelError,
    isSuccess: isJobLevelSuccess,
  } = useGetReportByAreaQuery({
    areaCode: code,
    group,
    projection: 'jobLevel',
    apiVersion,
    sessionId,
  })
  const jobLevel = jobLevelReportsData?.representation.jobLevel

  const {
    data: scoringReportsData,
    isLoading: isScoringLoading,
    isError: isScoringError,
    isSuccess: isScoringSuccess,
  } = useGetReportByAreaQuery({
    areaCode: code,
    group,
    projection: 'general',
    apiVersion,
    sessionId,
  })
  const scoring = scoringReportsData?.representation.scoring

  const {
    data: locationReportsData,
    isLoading: isLocationLoading,
    isError: isLocationError,
    isSuccess: isLocationSuccess,
  } = useGetReportByAreaQuery({
    areaCode: code,
    group,
    projection: 'location',
    apiVersion,
    sessionId,
  })
  const location = locationReportsData?.representation.location

  const {
    data: departmentReportsData,
    isLoading: isDepartmentLoading,
    isError: isDepartmentError,
    isSuccess: isDepartmentSuccess,
  } = useGetReportByAreaQuery({
    areaCode: code,
    group,
    projection: 'department',
    apiVersion,
    sessionId,
  })
  const department = departmentReportsData?.representation.department

  const dataByTab = getDataByTab(t, {
    jobLevel,
    group,
    location,
    department,
    sorted: false,
  })
  const tabs: Tab[] = getTabs(t, dataByTab)?.filter(
    ({ key }) =>
      key === 'byJobLevel' || key === 'byLocation' || key === 'byDepartment'
  )

  const isLoading =
    isJobLevelLoading ||
    isScoringLoading ||
    isLocationLoading ||
    isDepartmentLoading

  const isError =
    isJobLevelError || isScoringError || isLocationError || isDepartmentError

  const isSuccess =
    isJobLevelSuccess ||
    isScoringSuccess ||
    isLocationSuccess ||
    isDepartmentSuccess

  return (
    <StateHandler
      isError={isError}
      isSuccess={isSuccess}
      isLoading={isLoading}
      errorMessage={t('error.TryAgain')}
    >
      <Representation
        title={t('reports.representation.title')}
        tabs={tabs}
        dataByTab={dataByTab}
        benchmark={scoring?.[0]?.benchmark ?? 0}
        colorGetter={group === 'woman' ? COLOR_RULES_WOMEN : COLOR_RULES_POC}
        legend={getLegend(t, group)}
      />
      {group === 'poc' && <LinkToEmployeeGroups />}
    </StateHandler>
  )
}

const LinkToEmployeeGroups: FunctionComponent = () => {
  const { sidebarItems } = useMenuGroupAreas()

  const sidebarDemGroups = sidebarItems.find(item =>
    item.code.includes('group/dem_groups')
  )

  if (!sidebarDemGroups) {
    return null
  }

  const sidebarSubItem = sidebarDemGroups.items?.find(item =>
    item.code.includes('dem_ethnicity')
  )

  if (!sidebarSubItem) {
    return null
  }

  return (
    <CenteredText element="p" type="body" size="sm" fontWeight={400}>
      Dive into&nbsp;
      <RouterLink
        to={{
          pathname: sidebarSubItem.url,
        }}
      >
        {`${sidebarDemGroups.text} > ${sidebarSubItem.text}`}
      </RouterLink>
      &nbsp;for more detail
    </CenteredText>
  )
}
