import { shallowEqual } from 'react-redux'

import { useAuditBy } from 'hooks/useAuditBy'
import { AreaType } from 'store/area/types'
import {
  useGetAuditAreasQuery,
  useGetIsScorableQuery,
} from 'store/audit/auditApiWithQuery'
import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'
import { QueryStatus } from 'store/types'

// Only used by useMenuGroupAreas
// Could be turned into a single hook
export const useReportsMenu = (areaType?: AreaType) => {
  const { apiVersion, sessionId } = useAppSelector(
    (state: State) => ({
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer.sessionId,
    }),
    shallowEqual
  )
  const { data: isScorable } = useGetIsScorableQuery({ apiVersion, sessionId })

  const {
    data: areas,
    isSuccess: isAreasSucess,
    isLoading: isAreaLoading,
    isError: isAreaError,
  } = useGetAuditAreasQuery({
    sessionId,
    query: {},
  })

  const generalAreas = areas?.filter(area => area.type === AreaType.GENERAL)
  const secondaryArea = areas?.filter(
    area => area.type === (areaType ?? AreaType.SUB)
  )

  const { isSurveyCompleted, isCompanyAssessmentCompleted } = useAuditBy(
    sessionId,
    apiVersion
  )

  if (isAreaLoading || isAreaError) {
    return {
      menu: [],
      status: isAreaLoading ? QueryStatus.LOADING : QueryStatus.ERROR,
    }
  }

  if (
    isAreasSucess &&
    generalAreas &&
    generalAreas.length > 0 &&
    secondaryArea &&
    secondaryArea.length > 0
  ) {
    const menu = generalAreas.map(generalArea => {
      const isCompleted =
        generalArea.code === 'equality'
          ? isCompanyAssessmentCompleted
          : isSurveyCompleted

      return {
        code: generalArea.code,
        title: generalArea.title,
        enabled: areaType === AreaType.LOCAL || isScorable || isCompleted,
        url: `/reports/${generalArea.code}`,
        items:
          secondaryArea.length > 0
            ? secondaryArea
                ?.filter(
                  ({ parent, max }: any) =>
                    max &&
                    (areaType === AreaType.LOCAL
                      ? parent?.parentAreaCode === generalArea.code
                      : parent?.code === generalArea.code)
                )
                .map(({ code, title }: any) => ({ code, title }))
            : [],
      }
    })
    return { menu, status: QueryStatus.SUCCESS }
  }

  return { menu: [], status: QueryStatus.SUCCESS }
}
