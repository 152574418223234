import React from 'react'

import { useMenuGroupAreas } from './hooks/useMenuGroupAreas'
import { MenuGroup } from './MenuGroup'

export const MenuGroupAreas = () => {
  const { sidebarItems: sidebarAreaItems } = useMenuGroupAreas()

  return (
    // no-dd-sa:tsx-react/no-redundant-fragments
    <>
      {sidebarAreaItems.length > 0 &&
        sidebarAreaItems.map(item => <MenuGroup key={item.text} {...item} />)}
    </>
  )
}
