import React, { FunctionComponent } from 'react'

import { Flex } from '@theme-ui/components'
import { Link, useLocation } from 'react-router-dom'

import styled from 'styled-components'

import { makeTransition } from '@fairhq/common'

import { DisabledMenuIcon } from './DisabledMenuIcon'
import { IReportsSidebarSubMenuItem } from './types/reportsSidebarSubMenuItem'

const MenuLink = styled(Link)<{ $enabled: boolean }>`
  cursor: ${({ $enabled }) => ($enabled ? 'pointer' : 'default')};
`

export const SubMenuItem: FunctionComponent<IReportsSidebarSubMenuItem> = ({
  text,
  url,
  enabled = true,
}) => {
  const location = useLocation()

  const active = url ? location.pathname.startsWith(url) : false

  return (
    <MenuLink to={enabled ? url : '#'} $enabled={enabled}>
      <Flex
        variant="text.label"
        sx={{
          pointerEvents: enabled ? undefined : 'none',
          position: 'relative',
          pl: 9,
          pr: 4,
          py: 2,
          minHeight: 42,
          alignItems: 'center',
          color: active ? 'purple' : 'grey500',
          transition: makeTransition('color'),
          bg: active ? 'grey100' : 'white',
          '&:hover': { color: 'purple' },
          '&:before': {
            content: "''",
            position: 'absolute',
            top: 0,
            left: 0,
            width: 3,
            height: '100%',
            bg: 'purple',
            display: active ? 'block' : 'none',
          },
        }}
      >
        {enabled ? undefined : <DisabledMenuIcon />}
        {text}
      </Flex>
    </MenuLink>
  )
}
