import React from 'react'

import { useMenuGroupExperience } from './hooks/useMenuGroupExperience'
import { MenuGroup } from './MenuGroup'

export const MenuGroupExperience = () => {
  const { sidebarItems: sidebarExperienceItems } = useMenuGroupExperience()

  if (!sidebarExperienceItems) {
    return null
  }

  return (
    <MenuGroup key={sidebarExperienceItems?.text} {...sidebarExperienceItems} />
  )
}
