import { useEffect, useLayoutEffect, FunctionComponent } from 'react'

import { Heading } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import { useParams } from 'react-router-dom'

import { ReportsModularSidebar } from 'features/reportsModularSidebar/ReportsModularSidebar'
import { Layout } from 'layout/Layout'
import { PageTop } from 'layout/PageTop'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getBenchmarkOptionsByGroup } from 'store/reports/reportsSlice'
import { State } from 'store/state'
import { count as countSurvey } from 'store/survey/surveySlice'
import { customDecodeURIComponent } from 'utils/customDecodeURIComponent'
import { scrollTop } from 'utils/scrollTop'
import { tCode } from 'utils/tCode'

// import { ReportsSidebarGeneralSubAndGroups } from '../../reports/ReportsSidebarGeneralSubAndGroups'

import { DemographicGroupsTopLevelReports } from './DemographicGroupsTopLevelReports'
import { GenericGroupReports } from './GenericGroupReports'
import { GroupsSubLevelReports } from './GroupsSubLevelReports'

export const GroupsReports: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const tArea = tCode(t)
  const { code: rawCode = '', type } = useParams<{
    type: string
    code: string
  }>()
  const code = customDecodeURIComponent(rawCode)

  const { surveyStatus } = useAppSelector(
    (state: State) => ({
      surveyStatus: state.surveyReducer.status,
    }),
    shallowEqual
  )

  useLayoutEffect(() => {
    scrollTop()
  }, [code])

  useEffect(() => {
    if (!surveyStatus) {
      dispatch(countSurvey())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    if (type === 'dem_groups' && code) {
      dispatch(getBenchmarkOptionsByGroup(code))
    }
  }, [code, dispatch, type])

  const displayReport = () => {
    if (type !== 'dem_groups' && code) {
      return <GroupsSubLevelReports />
    }

    if (type === 'dem_groups' && !code) {
      return <DemographicGroupsTopLevelReports />
    }

    return <GenericGroupReports />
  }

  return (
    <Layout sidebar={<ReportsModularSidebar />} dark>
      <PageTop>
        <Heading>{tArea(code || type, 'title', code)}</Heading>
      </PageTop>
      {displayReport()}
    </Layout>
  )
}
