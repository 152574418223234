import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'

import { useAuditBy } from 'hooks/useAuditBy'
import { useGetEntitlementsQuery } from 'store/company/companyApiWithQuery'

import { EntitlementScopesEnum, EntitlementsEnum } from 'store/company/types'

import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'

export type StatusType = {
  title: string
  description: string
  percent: number
  button: {
    type: string
    external: boolean
    link: string
    label: string
  }
}

type StatusCardType = {
  [K in 'company' | 'survey' as string]: StatusType | undefined
}

export const useReportsStatus = (): StatusCardType => {
  const { t } = useTranslation()
  const { apiVersion, sessionId, companyId } = useAppSelector(
    (state: State) => ({
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer.sessionId,
      companyId: state.apiHeadersReducer.companyId,
    }),
    shallowEqual
  )
  const { data: companyEntitlements } = useGetEntitlementsQuery(undefined, {
    skip: !companyId,
  })

  const {
    isSurveyCompleted,
    isCompanyAssessmentCompleted,
    isSurveyStarted,
    isCompanyAssessmentStarted,
    surveyPercentage,
    assessmentPercentage,
  } = useAuditBy(sessionId, apiVersion)

  const button: any = {
    company: {
      pending: {
        type: 'primary',
        link: '/audit',
        label: t('reports.landing.company.button.pending'),
      },
      started: {
        type: 'primary',
        link: '/audit',
        label: t('reports.landing.company.button.started'),
      },
      completed: {
        type: 'secondary',
        link: '/reports/equality',
        label: t('reports.landing.company.button.completed'),
      },
    },
    survey: {
      pending: {
        type: 'primary',
        link: '/survey',
        label: t('reports.landing.survey.button.pending'),
      },
      started: {
        type: 'secondary',
        link: 'https://ask.fairhq.co/en/articles/5723455-how-do-i-resend-the-employee-survey',
        external: true,
        label: t('reports.landing.survey.button.started'),
      },
      completed: {
        type: 'secondary',
        link: '/reports/diversity',
        label: t('reports.landing.survey.button.completed'),
      },
    },
  }

  const buildStatus = ({
    type,
    started,
    completed,
    percent,
  }: {
    type: 'company' | 'survey'
    completed?: boolean
    started?: boolean
    percent: number
  }) => ({
    title: t(`reports.landing.${type}.title`),
    description: started
      ? t(`reports.landing.${type}.started`)
      : (completed && t(`reports.landing.${type}.completed`)) ||
        t(`reports.landing.${type}.pending`),
    percent,
    button: started
      ? button[type].started
      : (completed && button[type].completed) || button[type].pending,
  })

  const showCompanyAssessment = {
    company: companyEntitlements?.scopes.includes(EntitlementScopesEnum.REPORTS)
      ? buildStatus({
          type: 'company',
          completed: isCompanyAssessmentCompleted,
          started: isCompanyAssessmentStarted,
          percent: assessmentPercentage,
        })
      : undefined,
  }

  const showEmployeeSurvey = {
    survey:
      companyEntitlements?.entitlements.includes(
        EntitlementsEnum.EMPLOYEE_SURVEY_AUDIT
      ) || companyEntitlements?.entitlements.includes(EntitlementsEnum.SURVEYS)
        ? buildStatus({
            type: 'survey',
            completed: isSurveyCompleted,
            started: isSurveyStarted,
            percent: surveyPercentage,
          })
        : undefined,
  }
  return {
    ...showCompanyAssessment,
    ...showEmployeeSurvey,
  }
}
