import _ from 'lodash'
import { shallowEqual } from 'react-redux'

import { ApiVersion, SessionId } from 'store/apiHeaders/types'
import {
  useGetAuditCompanyQuery,
  useGetAuditDocumentsQuery,
  useGetAuditSurveyQuery,
} from 'store/audit/auditApiWithQuery'

import { AuditStatus } from 'store/audit/types'
import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'

import { QueryStatus } from 'store/types'
import { MIN_SURVEY_COMPLETION } from 'utils/minSurveyCompletion'

export const useAuditBy = (
  sessionId?: SessionId,
  apiVersion?: ApiVersion
): {
  audit: AuditStatus | undefined
  percentage: number
  completed: boolean | undefined
  auditQueryStatus: QueryStatus | undefined
  assessmentPercentage: number
  surveyPercentage: number
  isCompanyAssessmentCompleted?: boolean
  isCompanyAssessmentStarted?: boolean
  isSurveyCompleted?: boolean
  isSurveyStarted?: boolean
} => {
  const { apiHeadersSessionId, apiHeadersApiVersion } = useAppSelector(
    (state: State) => ({
      apiHeadersSessionId: state.apiHeadersReducer.sessionId,
      apiHeadersApiVersion: state.apiHeadersReducer.apiVersion,
    }),
    shallowEqual
  )

  const values = {
    sessionId: sessionId ?? apiHeadersSessionId,
    apiVersion: apiVersion ?? apiHeadersApiVersion,
  }

  const {
    data: company,
    isLoading: isCompanyLoading,
    isError: isCompanyError,
  } = useGetAuditCompanyQuery({
    sessionId: values.sessionId,
    apiVersion: values.apiVersion,
  })

  const {
    data: documents,
    isLoading: isDocumentsLoading,
    isError: isDocumentsError,
  } = useGetAuditDocumentsQuery({
    sessionId: values.sessionId,
    apiVersion: values.apiVersion,
  })
  const {
    data: survey,
    isLoading: isSurveyLoading,
    isError: isSurveyError,
  } = useGetAuditSurveyQuery({
    sessionId: values.sessionId,
    apiVersion: values.apiVersion,
  })

  const percentage =
    company && survey && documents
      ? Math.round(_.mean([company.percent, survey.percent, documents.percent]))
      : 0

  const assessmentPercentage =
    company && documents
      ? Math.round(_.mean([company.percent, documents.percent]))
      : 0

  const surveyPercentage = survey ? Math.round(survey.percent) : 0

  const completed = !!(
    assessmentPercentage === 100 && surveyPercentage >= MIN_SURVEY_COMPLETION
  )

  if (isCompanyError || isDocumentsError || isSurveyError) {
    return {
      auditQueryStatus: QueryStatus.ERROR,
      percentage,
      completed: undefined,
      audit: undefined,
      assessmentPercentage,
      surveyPercentage,
      isCompanyAssessmentCompleted: false,
      isSurveyCompleted: false,
    }
  }

  if (isCompanyLoading || isDocumentsLoading || isSurveyLoading) {
    return {
      auditQueryStatus: QueryStatus.LOADING,
      percentage,
      completed: undefined,
      audit: undefined,
      assessmentPercentage,
      surveyPercentage,
      isCompanyAssessmentCompleted: false,
      isSurveyCompleted: false,
    }
  }

  return {
    auditQueryStatus: QueryStatus.SUCCESS,
    percentage,
    completed,
    assessmentPercentage,
    surveyPercentage,
    audit:
      company && survey && documents
        ? { company, survey, documents }
        : undefined,
    isCompanyAssessmentCompleted:
      (company?.percent ?? 0) === 100 && (documents?.percent ?? 0) === 100,
    isSurveyCompleted: (survey?.percent ?? 0) >= MIN_SURVEY_COMPLETION,
    isCompanyAssessmentStarted:
      assessmentPercentage > 0 && assessmentPercentage < 100,
    isSurveyStarted: (survey?.surveys && survey.surveys.length > 0) || false,
  }
}
